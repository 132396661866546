import { Box } from '@mui/joy'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { styled } from '@mui/joy/styles'
import Container from '../Container/Container'
import { Flex } from '../Flex'

const NavBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}))

const NavigationBar = dynamic(() => import('./NavigationBar'))

export default function DesktopHeader({ menuItems }) {
    return (
        <Box
            sx={{
                left: 0,
                right: 0,
                top: 0,
                // bottom: 0,
                height: 64,
                zIndex: 10,
                borderBottom: '1px solid #dae0e4',
                backgroundColor: '#fff',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: '#fff',
                },
            }}
        >
            <Container>
                <Flex
                    py={{
                        xs: '16px',
                        sm: 0,
                    }}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        height="32px"
                        width="128px"
                        position="relative"
                        cursor="pointer"
                        component="a"
                        href="/"
                    >
                        <Image
                            quality={80}
                            src="/images/logo.png"
                            fill
                            alt="EZ Capital Logo"
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    </Box>

                    <NavBar>
                        <NavigationBar isDropdown menuItems={menuItems} />
                    </NavBar>
                </Flex>
            </Container>
        </Box>
    )
}
